import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as fromLayout from '../../layout';
import {
  LAUNCH_DARKLY_CLIENT,
  LaunchDarklyFlagSet,
} from '../launch-darkly.client';
import { FeatureFlagsActions } from './feature-flags.actions';
import { FeatureFlagsState } from './feature-flags.reducer';

@Injectable()
export class FeatureFlagsEffects {
  private readonly actions$ = inject(Actions);
  private readonly featureFlagStore = inject(Store<FeatureFlagsState>);
  private readonly launchDarklyClient = inject(LAUNCH_DARKLY_CLIENT);

  initClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureFlagsActions.initClient),
      switchMap(async () => {
        await this.launchDarklyClient.waitForInitialization();

        this.launchDarklyClient.on('error', (error: Error) => {
          this.featureFlagStore.dispatch(
            FeatureFlagsActions.onError({ error })
          );
        });
      }),
      map(() => FeatureFlagsActions.initClientSuccess()),
      catchError((initError: Error) =>
        of(FeatureFlagsActions.initClientFailure({ initError }))
      )
    )
  );

  loadFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureFlagsActions.initClientSuccess),
      map(() =>
        /**
         * How the all flags feature works in the LaunchDarkly SDKs:
         * https://docs.launchdarkly.com/sdk/features/all-flags
         */
        FeatureFlagsActions.loadFeatureFlags({
          featureFlags: Object.entries(this.launchDarklyClient.allFlags()).map(
            ([key, value]: [string, boolean]) => ({
              key,
              value,
            })
          ),
        })
      )
    )
  );

  identifyUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureFlagsActions.identifyUser),
        map(({ user }) => {
          if (user) {
            return {
              ...environment.launchDarkly.context,
              user: {
                kind: 'user',
                /**
                 * Keys must be consistent, which means the same person must correspond
                 * to the same key across different services to contribute to consistent
                 * flag evaluations.
                 * https://docs.launchdarkly.com/sdk/features/context-config
                 */
                key: user.email,
                email: user.email,
                name: `${user.firstName} ${user.lastName}`,
                sub: user.id,
                anonymous: false,
              },
            };
          } else {
            return environment.launchDarkly.context;
          }
        }),
        switchMap(
          async (ldContext) => await this.launchDarklyClient.identify(ldContext)
        )
      ),
    { dispatch: false }
  );

  onSetIsMobile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromLayout.LayoutActions.setIsMobile),
        switchMap(async ({ isMobile }) => {
          await this.launchDarklyClient.identify({
            ...environment.launchDarkly.context,
            ...this.launchDarklyClient.getContext(),
            device: {
              key: isMobile ? 'mobile' : 'desktop',
              name: isMobile ? 'mobile' : 'desktop',
            },
          });
        })
      ),
    { dispatch: false }
  );

  onChangeSync$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureFlagsActions.initClientSuccess),
        tap(() => {
          /**
           * How the change event works in the LaunchDarkly SDKs:
           * https://docs.launchdarkly.com/sdk/features/flag-changes#javascript
           */
          this.launchDarklyClient.on('change', (flags: LaunchDarklyFlagSet) => {
            this.featureFlagStore.dispatch(
              FeatureFlagsActions.onChangeSyncSuccess({
                featureFlags: Object.entries(flags).map(([key, value]) => ({
                  key,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  value: (value?.current as boolean) || false,
                })),
              })
            );
          });
        })
      ),
    { dispatch: false }
  );

  onInitClientFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureFlagsActions.initClientFailure),
        tap(({ initError }) => {
          throw initError;
        })
      ),
    { dispatch: false }
  );

  onError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureFlagsActions.onError),
        tap(({ error }) => {
          throw error;
        })
      ),
    { dispatch: false }
  );
}
