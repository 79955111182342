import { Component, inject, OnInit, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import * as fromLayout from '../../layout';
import { TopBarMobileResponsive } from '../components/top-bar.mobile.responsive';
import { ChatActions } from '../store/chats.actions';
import { ChatsState } from '../store/chats.reducer';
import { AssistantCardComponent } from './components/assistant-card.component';
import { AssistantModalComponent } from './components/assistant-save-modal.component';
import {
  AssistantActions,
  AssistantModalActions,
  AssistantsActions,
} from './store/assistants.actions';
import { assistantsFeature, AssistantsState } from './store/assistants.reducer';

type AssistantMobileDrawerAction = 'create-assistant' | 'data-boxes';

@Component({
  selector: 'squadbox-assistants',
  imports: [
    TranslateModule,
    fromSeriousSystem.ButtonDirective,
    fromSeriousSystem.IconButtonDirective,
    fromSeriousSystem.UseNavigationIconDirective,
    fromSeriousSystem.DrawerComponent,
    fromSeriousSystem.OptionMenuComponent,
    TopBarMobileResponsive,
    AssistantCardComponent,
    AssistantModalComponent,
  ],
  template: `
    @if(isMobile()) {
    <squadbox-top-bar-mobile
      [title]="'ASSISTANTS.TITLE' | translate"
      [hideMoreOptionsButton]="false"
      (chevronLeftButtonClicked)="handleChevronLeftButtonCTA()"
      (moreOptionsClicked)="isMobileDrawerOpened.set(true)"
    >
    </squadbox-top-bar-mobile>
    } @else {
    <header
      [class.grid-area]="'topbar'"
      class="flex flex-row place-content-end gap-4 py-4 px-6"
    >
      <button
        sdButton
        color="neutral"
        variant="outline"
        size="sm"
        (click)="handleCreateAssistantCTA()"
      >
        {{ 'ASSISTANTS.CREATE_ASSISTANT' | translate }}
      </button>
    </header>
    }

    <section
      [class.grid-area]="'content'"
      [class.justify-center]="isMobile() && !assistants().length"
      class="
        flex flex-col gap-8 h-full
        w-full max-w-180 mx-auto py-6 px-5
        tablet-portrait:pt-20 tablet-portrait:px-0 tablet-portrait:pl-3
        align-center
        max-h-[calc(100svh - 5rem)]
        overflow-y-auto hide-scrollbar
      "
    >
      @if(!isMobile() || !assistants().length) {
      <div
        class="mx-auto pointer-events-none min-h-14"
        sdIconButton
        variant="outlined"
        color="neutral"
        size="lg"
      >
        <svg sdUseNavigationIcon="dashboard"></svg>
      </div>
      <div
        class="
          flex flex-col gap-1 text-center
          [&>p]:typo-p3 [&>p]text-neutral-500
        "
      >
        <h1 class="typo-h6 font-semibold">
          {{ 'ASSISTANTS.TITLE' | translate }}
        </h1>
        @if (assistants().length) {
        <p>
          {{ 'ASSISTANTS.SUBTITLE' | translate }}
        </p>
        } @else {
        <p>
          {{ 'ASSISTANTS.SUBTITLE_NO_ASSISTANTS_LINE_1' | translate }}
        </p>
        <p>
          {{ 'ASSISTANTS.SUBTITLE_NO_ASSISTANTS_LINE_2' | translate }}
        </p>
        }
      </div>
      }

      <div class="hidden">TODO: Add chips for filtering</div>

      <!-- Assistants List -->
      @if (assistants().length) {
      <ul class="flex flex-col gap-6">
        @for (assistant of assistants(); track assistant.uuid) {
        <squadbox-assistant-card
          [logo]="assistant.logo"
          [name]="assistant.name"
          [description]="assistant.description"
          [status]="assistant.status"
          [isAdded]="assistant.active"
          (addClicked)="handleAddAssistantCTA({ uuid: assistant.uuid })"
          (removeClicked)="handleRemoveAssistantCTA({ uuid: assistant.uuid })"
          (editClicked)="handleEditAssistantCTA({ uuid: assistant.uuid })"
        ></squadbox-assistant-card>
        }
      </ul>
      } @else {
      <button
        sdButton
        color="primary"
        size="sm"
        class="mx-auto"
        (click)="handleCreateAssistantCTA()"
      >
        {{ 'ASSISTANTS.CREATE_ASSISTANT' | translate }}
      </button>
      }
    </section>

    <!-- More options -->
    @if(isMobile()) {
    <sd-drawer
      class="z-10"
      [isOpen]="isMobileDrawerOpened()"
      (closeClicked)="isMobileDrawerOpened.set(false)"
    >
      @for (option of mobileDrawerOptions(); track option.label) {
      <sd-option-menu
        [option]="option"
        (triggerAction)="handleMobileDrawerTriggerActionCTA($event)"
      ></sd-option-menu>
      }
    </sd-drawer>
    }

    <squadbox-assistant-save-modal></squadbox-assistant-save-modal>
  `,
  styles: [
    `
      :host {
        @apply grid grid-rows-[min-content_auto] h-svh w-full;
        grid-template-areas:
          'topbar'
          'content';
      }
    `,
  ],
})
export class AssistantsPage implements OnInit {
  private readonly chatsStore = inject(Store<ChatsState>);
  private readonly assistantsStore = inject(Store<AssistantsState>);
  private readonly layoutStore = inject(Store<fromLayout.LayoutState>);
  private readonly translateService = inject(TranslateService);

  public readonly isMobileDrawerOpened = signal(false);
  public readonly mobileDrawerOptions = signal<fromSeriousSystem.OptionMenu[]>([
    {
      label: this.translateService.instant(
        'ASSISTANTS.CREATE_ASSISTANT'
      ) as string,
      icon: 'edit',
      action: 'create-assistant' as AssistantMobileDrawerAction,
    },
    {
      label: this.translateService.instant('ASSISTANTS.DATA_BOXES') as string,
      icon: 'archive',
      action: 'data-boxes' as AssistantMobileDrawerAction,
      disabled: true,
    },
  ]);

  public readonly assistants = this.assistantsStore.selectSignal(
    assistantsFeature.selectAll
  );
  public readonly isMobile = this.layoutStore.selectSignal(
    fromLayout.layoutFeature.selectIsMobile
  );

  ngOnInit(): void {
    this.assistantsStore.dispatch(AssistantsActions.loadAssistants());
  }

  public handleAddAssistantCTA(event: { uuid: string }): void {
    this.assistantsStore.dispatch(
      AssistantActions.addAssistant({ uuid: event.uuid })
    );
  }

  public handleRemoveAssistantCTA(event: { uuid: string }): void {
    this.assistantsStore.dispatch(
      AssistantActions.removeAssistant({ uuid: event.uuid })
    );
  }

  public handleEditAssistantCTA(event: { uuid: string }): void {
    this.assistantsStore.dispatch(
      AssistantModalActions.openSaveAssistantModal()
    );
    this.assistantsStore.dispatch(
      AssistantActions.selectAssistant({ uuid: event.uuid })
    );
  }

  public handleChevronLeftButtonCTA(): void {
    this.chatsStore.dispatch(ChatActions.openThreadsListingContainer());
  }

  public handleMobileDrawerTriggerActionCTA(action: string): void {
    switch (action as AssistantMobileDrawerAction) {
      case 'create-assistant':
        this.handleCreateAssistantCTA();
        break;
      case 'data-boxes':
        break;
    }
  }

  public handleCreateAssistantCTA(): void {
    this.assistantsStore.dispatch(AssistantActions.unselectAssistant());

    this.assistantsStore.dispatch(
      AssistantModalActions.openSaveAssistantModal()
    );
  }
}
